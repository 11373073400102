import { useCallback, useEffect } from 'react';

import { type TeamRandomizerNotification } from '../../types/notification';
import { ShuffleIcon } from '../icons/ShuffleIcon';
import { XIcon } from '../icons/XIcon';
import { useNotificationDataSource } from '../Notification/Context';
import { type BaseNotificationProps } from '../Notification/type';
import { useTeamRandomizerStepDetail } from './Context';

const TeamRandomizerNotifcationView = (
  props: BaseNotificationProps & {
    notification: TeamRandomizerNotification;
  }
): JSX.Element | null => {
  const detail = useTeamRandomizerStepDetail();
  const metadata = props.notification.metadata;
  const { dismiss: dismissNotification } = useNotificationDataSource();

  const handleClose = useCallback(() => {
    dismissNotification(props.notification.id);
    if (props.closeToast) {
      props.closeToast();
    }
  }, [dismissNotification, props]);

  const canClose =
    detail?.step !== 'countdown' ||
    detail.taskId !== metadata.taskId ||
    detail.timestep === 0;

  useEffect(() => {
    if (canClose) {
      handleClose();
    }
  }, [handleClose, canClose]);

  if (canClose) {
    return null;
  }

  return (
    <div className='flex flex-row items-center w-100 text-sm font-white p-3 h-15 rounded-xl bg-secondary'>
      <div className='text-primary'>
        <ShuffleIcon className='w-5 h-5 fill-current' />
      </div>
      <div className='text-2xs text-white ml-2'>
        <div>Host will randomize the Teams in {detail.timestep}...</div>
      </div>
      <div className='ml-auto' />
      <div className='cursor-pointer' onClick={handleClose}>
        <XIcon />
      </div>
    </div>
  );
};

// Required for lazy import
// eslint-disable-next-line import/no-default-export
export default TeamRandomizerNotifcationView;
